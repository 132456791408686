<template>
  <div v-if="filteredList.length" class="navigation-panel__section">
    <div v-if="section.title" class="navigation-panel__section-title font font_m font_bold font_uppercase">
      {{ section.title }}
    </div>

    <ul class="navigation-panel__section-list">
      <li
        v-for="(link, index) in filteredList"
        :key="index"
        class="navigation-panel__section-item font font_m"
        :class="{'navigation-panel__section-item_active' : isCurrentPage(link.route) || isActiveRoute(link.active)}"
      >
        <nuxt-link
          :to="{ name: link.route, params: link.params }"
          class="navigation-panel__section-link"
          :class="{ 'navigation-panel__section-link_active' : isCurrentPage(link.route) || isActiveRoute(link.active) }"
        >
          {{ link.title }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
import './index.css';
import {mapState} from 'vuex';

export default {
  name: 'NavigationPanelSection',
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('auth', ['user']),

    filteredList() {
      return this.section.list.filter(link => {
        return !link.meta
          || (this.user && !this.user.referralPartner && link.meta && link.meta.exceptReferalPartner)
          || (this.user && this.user.referralPartner && link.meta
            && link.meta.onlyReferalPartner && !link.meta.exceptReferalPartner)
      })
    },
  },
  methods: {
    isCurrentPage(route) {
      return route === this.$route.name;
    },
    isActiveRoute(active) {
      return active.includes(this.$route.name)
    },
  },
}
</script>
